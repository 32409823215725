/* Global flex tokens */

/* This fills the remaining space, by using flexbox. */
.fill-remaining-space {
  flex: 1 1 auto;
}

/* Flex filler */
.filler {
  flex: 1 1 auto;
}

/* Flex Layout*/
.flex {
  display: flex !important;
}
.flex-row {
  display: flex !important;
  flex-direction: row !important;
}
.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.flex-col,
.flex-column {
  display: flex !important;
  flex-direction: column;
}
.flex-col-reverse,
.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-0-0-auto {
  flex: 0 0 auto;
}
.flex-1 {
  flex: 1;
}
.flex-1-1-auto {
  flex: 1 1 auto;
}
.flex-1-1-100 {
  flex: 1 1 100%;
}
.flex-1-0-auto {
  flex: 1 0 auto;
}
.flex-1-0-100 {
  flex: 1 0 100%;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
/* https://tailwindcss.com/docs/align-items */
.align-items-start {
  display: flex;
  align-items: flex-start;
}
.align-items-end {
  display: flex;
  align-items: flex-end;
}
.align-items-center {
  display: flex;
  align-items: center;
}
.align-items-baseline {
  display: flex;
  align-items: baseline;
}
.align-items-stretch {
  display: flex;
  align-items: stretch;
}
/* https://tailwindcss.com/docs/justify-content */
.justify-start {
  display: flex;
  justify-content: flex-start;
}
.justify-end {
  display: flex;
  justify-content: flex-end;
}
.justify-center {
  display: flex;
  justify-content: center;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.justify-around {
  display: flex;
  justify-content: space-around;
}
.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}
/* https://tailwindcss.com/docs/place-content */
.place-content-center {
  display: flex;
  place-content: center;
}
.place-content-start {
  display: flex;
  place-content: flex-start;
}
.place-content-end {
  display: flex;
  place-content: flex-end;
}
.place-content-between {
  display: flex;
  place-content: space-between;
}
.place-content-around {
  display: flex;
  place-content: space-around;
}
.place-content-evenly {
  display: flex;
  place-content: space-evenly;
}
.place-content-stretch {
  display: flex;
  place-content: stretch;
}
/* https://tailwindcss.com/docs/place-items */
.place-items-start {
  display: flex;
  place-items: flex-start;
}
.place-items-end {
  display: flex;
  place-items: flex-end;
}
.place-items-center {
  display: flex;
  place-items: center;
}
.place-items-stretch {
  display: flex;
  place-items: stretch;
}

/* Most used */
.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-col-center,
.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Flex Gap */
.flex-gap-row-5 {
  display: flex;
  flex-direction: row;
  gap: 0 5px;
}

.flex-gap-row-10 {
  display: flex;
  flex-direction: row;
  gap: 0px 10px;
}

.flex-gap-row-20 {
  display: flex;
  flex-direction: row;
  gap: 0 20px;
}

.flex-gap-row-30 {
  display: flex;
  flex-direction: row;
  gap: 0 30px;
}

.flex-gap-row-40 {
  display: flex;
  flex-direction: row;
  gap: 0 40px;
}

.flex-gap-col-5,
.flex-gap-column-5 {
  display: flex;
  flex-direction: column;
  gap: 10px 0px;
}

.flex-gap-col-10,
.flex-gap-column-10 {
  display: flex;
  flex-direction: column;
  gap: 10px 0px;
}

.flex-gap-col-20,
.flex-gap-column-20 {
  display: flex;
  flex-direction: column;
  gap: 20px 0px;
}

.flex-gap-col-30,
.flex-gap-column-30 {
  display: flex;
  flex-direction: column;
  gap: 30px 0px;
}

.flex-gap-col-40,
.flex-gap-column-40 {
  display: flex;
  flex-direction: column;
  gap: 40px 0px;
}

/* Flex Gap for wrapping elements */
.flex-gap-row-wrap-20 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
}

.flex-gap-row-wrap-10 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 10px;
}

.flex-gap-0 {
  display: flex;
  gap: 30px;
}

.flex-gap-5 {
  display: flex;
  gap: 5px;
}

.flex-gap-10 {
  display: flex;
  gap: 10px;
}

.flex-gap-20 {
  display: flex;
  gap: 20px;
}

.flex-gap-30 {
  display: flex;
  gap: 30px;
}

.flex-gap-40 {
  display: flex;
  gap: 40px;
}

/* https://tailwindcss.com/docs/align-self */
.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.justify-self-auto {
  justify-self: auto;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}
