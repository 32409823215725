.column {
  flex: 1 1 100px;
}

// fixed columns
.column-checkbox {
  flex: 0 1 52px;
  padding-left: 10px !important;
  padding-right: 10px !important;

  &:first-of-type {
    padding-left: 0px !important;
    padding-right: 0px !important;
    justify-content: center;
    text-align: center;
  }
}

.column-date {
  flex: 0 1 130px;
}

.column-datetime {
  flex: 0 0 160px;
}

.column-id {
  flex: 0 0 80px;
}

.column-image {
  flex: 0 0 60px;
  /* Always first column */
  padding-left: 0px !important;
  min-width: 65px;
}

.column-color {
  flex: 0 0 35px;
}

.column-multi-link {
  flex: 0 0 130px;
}

.column-multi-organization {
  flex: 0 0 180px;
}

.column-email {
  flex: 0 0 180px;
}

.column-options {
  flex: 0 0 52px;
}

.column-reorder {
  flex: 0 0 52px;
  cursor: grab;
}

// shrinking columns
.column-address-number {
  flex: 0 1 80px;
}

.column-phone {
  flex: 0 1 130px;
}

.column-system-status {
  flex: 0 1 130px;
}

.column-status {
  flex: 0 0 130px;
}

// growing columns

.column-main {
  flex: 3 0 100px;
}

// shrinking and growing columns
.column-address-field {
  flex: 1 1 100px;
}

.mat-mdc-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.column-indicator {
  flex: 1 1 80px;

  app-status-indicator {
    max-width: 100%;
  }
}

.column-single-link {
  flex: 1 1 140px;
}

.column-name-with-counter {
  flex: 1 1 240px;
}
