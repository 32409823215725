@use '@angular/material' as mat;

/* Inputs */

::ng-deep .mat-mdc-input-element:read-only {
  cursor: default !important;
}

::ng-deep .mat-mdc-input-element[readonly='true'] {
  cursor: default !important;
}

::ng-deep .mat-mdc-input-element[readonly='true']:hover {
  cursor: default !important;
}

.dropdown-select-panel-set-width {
  min-width: initial !important;
  overflow-anchor: none;
}

.dropdown-select-panel {
  overflow-anchor: none;
}

.dropdown-select-panel-set-width ::ng-deep .mat-mdc-option {
  max-width: 250px !important;
}

.mat-accent .mat-mdc-input-element {
  caret-color: var(--primaryThemeColor);
}

/* Form Fields */
// Support custom usage of padding (not supported in mdc anymore, but used as custom class)
.mat-form-field-wrapper {
  padding-bottom: var(--material-input-padding-bottom);
}

.my-mat-form-field {
  margin: 2px 0px;
  max-width: 350px;
  width: 100%;

  &.full-width {
    max-width: unset;
  }
}

.my-mat-form-field-outline {
  margin: 2px 0px;
}

.my-mat-form-field-app-edit-show-input {
  max-width: 350px;
  width: 100%;
}

.my-mat-form-field-full-width {
  max-width: initial;
  width: 100% !important;
}

.my-mat-form-field-large {
  width: 100%;
  margin: 5px 0px;
}

.my-mat-form-field-narrow {
  max-width: 165px;
}

.my-mat-form-field-super-narrow {
  max-width: 90px;
}

.my-mat-form-field ::ng-deep .mat-select-disabled .mat-select-value {
  color: initial !important;
}

.mat-form-field-disabled mat-icon,
.mat-form-field-disabled mat-hint {
  color: var(--mdc-outlined-text-field-disabled-input-text-color);
}

.mat-mdc-form-field.no-padding {
  .mat-mdc-form-field-infix {
    padding: 0px !important;
  }
}

.mat-mdc-form-field-error {
  font-size: var(--font-size-Dot75rem);
}

.prefilled {
  .mat-mdc-form-field-appearance-outline,
  &.mat-mdc-form-field-appearance-outline,
  &.my-mat-form-field,
  &.mat-expansion-panel-mat-field {
    .mdc-text-field--outlined,
    .checkbox-outline,
    .mat-expansion-panel-body {
      background-color: var(--prefillColor);
      border-radius: var(--borderRadius);
    }
  }

  ::ng-deep .mat-mdc-form-field-appearance-outline .mat-form-field-outline {
    background-color: var(--prefillColor);
  }

  ::ng-deep .mat-mdc-text-field-wrapper {
    background-color: var(--prefillColor);
    border-radius: var(--borderRadius);
  }

  &.rich-text-mobile-wrapper {
    background-color: var(--prefillColor);
    border-radius: var(--borderRadius);
  }

  &.mat-expansion-panel-mat-field .floating-label {
    background: transparent;
  }

  &.rich-text-mobile-wrapper {
    background-color: var(--prefillColor);
    border-radius: var(--borderRadius);
  }

  &.mat-expansion-panel-mat-field .floating-label {
    background: transparent;
  }
}

.manual-mat-error {
  font-size: var(--font-size-75-pc);
  padding: 0 1em;
  margin-top: 0.6666666667em;
}

.margin-error-two-line {
  margin-bottom: 20px;
  transition: margin-bottom 0.5s;
}

.margin-error-one-line {
  margin-bottom: 10px;
  transition: margin-bottom 0.5s;
}

/* Hints*/
.mat-mdc-form-field-hint,
.mat-mdc-form-field-hint,
.hint-label {
  color: var(--grey700);
  font-weight: var(--font-weight-regular);
}

.hint-label {
  font-size: var(--font-size-12px);
  line-height: var(--line-height-18px);
}

.mat-hint-detail {
  margin-bottom: 15px;
}

.hint-start {
  display: flex;
  gap: 5px;
  align-items: flex-start;
  justify-content: center;
}

.hint-icon {
  font-size: var(--xSmallIcon) !important;
  height: var(--xSmallIcon) !important;
  width: var(--xSmallIcon) !important;
  min-width: var(--xSmallIcon) !important;
  cursor: pointer;
  color: var(--statusRed);
  overflow: visible !important;
}

/* Autocomplete */
.special-mat-autocomplete {
  box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.28) !important;
  border: 1px solid var(--borderColor) !important;
  margin-top: 1px !important;
}

.mat-mdc-form-field-icon-suffix .mat-mdc-icon-button:not([disabled]).mat-mdc-button-base {
  color: var(--iconColor);
}

.mat-mdc-form-field-icon-suffix {
  padding-right: 8px !important;
}

.mdc-form-field > label {
  font-weight: var(--font-weight-regular);
}

/* Form Field for legacy & autocomplete e.g. chiplist, checkbox*/
.mat-mdc-form-field-focus-overlay {
  background-color: var(--grey200);
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.mat-static-label {
  .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: none;
    color: var(--mdc-filled-text-field-label-text-color) !important;
  }
  .mdc-text-field--filled .mat-mdc-floating-label {
    transform: none;
  }
}

.form-field-density-3 {
  @include mat.form-field-density(-3);
}
