/* Custom Feature Global Styling, Reusable Tokens and Often Reused Classes */

/* Global Feature CSS */
@import './styles/core.scss';
@import './styles/notifications.scss';
@import './styles/mobile.scss';
@import './styles/forms.scss';
@import './styles/landing.scss';
@import './styles/settings.scss';
@import './styles/flex.scss';
@import './styles/layout.scss';
@import './styles/dialogs.scss';
@import './styles/scheduling-board.scss';
@import './styles/scrolling.scss';
@import './styles/remberg-ui.scss';

/* Global Mixins */
@import './styles/utils.scss';

/* Achieve 100% width */
.full-width {
  width: 100%;
}

/* Achieve 100% height */
.full-height {
  height: 100%;
}

.height-inherit {
  height: inherit;
}

/* CSS class for hiding */
.hide {
  display: none !important;
}

/* Override for cursor hover */
.is-clickable:hover {
  cursor: pointer !important;
}

/* Override for cursor focus */
.is-clickable:focus {
  outline: none !important;
}

/* Override for dragging hover */
.is-draggable:hover {
  user-select: none !important;
}
.is-draggable:hover {
  cursor: grab !important;
}

.empty-list-bottom-margin {
  margin-bottom: 10px;
}

.text-ellipsis,
.text-ellipsis * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.no-border {
  border: 0 !important;
}

/* Margin and Padding tokens */
.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-top-10 {
  margin-top: 20px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.no-padding {
  padding: 0px;
}

/* Used for sidenav dividers to span over the 20px padding */
.sidenav-divider,
.mat-divider.sidenav-divider {
  margin: 0px -20px;
}

/* Overflow */
.overflow-hidden {
  overflow: hidden !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

/* Comma separated items */
.comma-separated > * {
  display: inline-block;

  &:not(:last-child)::after {
    content: ',';
    margin-right: 2pt;
  }
}

/* Save button */
.save-button-is-saving {
  .spinner-container {
    // Have to override spinner styles here for saving spinner
    margin-top: 0.6em !important;
    margin-bottom: 0px !important;
    margin-right: 0.5em;
  }
  .mdc-button__label {
    display: flex !important;
  }
}

.website-url,
.contact-email {
  color: inherit;
}

.placeholder-text {
  margin: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  font-size: var(--font-size-14px);
  color: var(--grey600);
}

.highlight-match {
  background-color: inherit;
  color: inherit;
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-20px);
}

.multi-line-list-option {
  height: 60px !important;
  display: flex !important;
  align-items: center !important;

  &.is-selected {
    background-color: var(--grey100);
  }
}

.preview-card-content {
  align-items: center;
  width: calc(100% - 10px);
  overflow: hidden;
}

/* Basic Colors */
.status-green {
  color: var(--statusGreen);
}

.status-red {
  color: var(--statusRed);
}

.status-orange {
  color: var(--statusOrange);
}

.status-warn {
  color: var(--statusWarn);
}

.status-blue {
  color: var(--statusBlue);
}

.debug-pre {
  font-size: 8px; // stylelint-disable-line scale-unlimited/declaration-strict-value
  line-height: 10px; // stylelint-disable-line scale-unlimited/declaration-strict-value
}
