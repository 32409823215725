// Leaflet
@import '~leaflet/dist/leaflet.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

:root {
  --iconConeWidth: auto;
  --iconConeHeight: 30px;
  --iconSquare: 30px;
}

.marker-cluster {
  div {
    height: var(--iconSquare);
    width: var(--iconSquare);
  }

  background-position-x: initial;
  background-repeat: no-repeat;
  background: initial;
}

.marker-cluster-large div,
.marker-cluster-medium div,
.marker-cluster-small div,
.marker-cluster-large,
.marker-cluster-medium,
.marker-cluster-small {
  height: var(--iconSquare) !important;
  width: var(--iconSquare) !important;
  margin: 0;
}

.leaflet-marker-icon.inline-svg-icon > svg {
  width: var(--iconConeWidth);
  height: var(--iconConeHeight);

  path.location-background {
    fill: var(--primaryThemeColor);
  }

  path.location-icon {
    fill: var(--textColorOnPrimaryBackground);
  }

  &:hover {
    path.location-background {
      fill: var(--primaryThemeColorDark);
    }
  }
}

#question-marker {
  fill: var(--primaryThemeColor) !important;
}

#question-marker-II {
  fill: var(--primaryThemeColor) !important;
}

#Emojione1_2754 {
  fill: var(--textColorOnPrimaryBackground) !important;
}

.leaflet-marker-icon.inline-svg-icon-oem > svg,
.leaflet-marker-icon svg {
  fill: var(--primaryThemeColor);
  width: var(--iconConeWidth);
  height: var(--iconConeHeight);
}

.leaflet-marker-icon:focus {
  outline: none !important;
}

.marker-cluster-small {
  color: var(--textColorOnPrimaryBackground) !important;
}

.marker-cluster-small div {
  background-color: var(--primaryThemeColor) !important;
  font-size: var(--font-size-12px);
  font-family: var(--roboto);
}

.marker-cluster-medium {
  color: var(--textColorOnPrimaryBackground) !important;
  background-color: var(--primaryThemeColor) !important;
  font-family: var(--roboto);
}

.marker-cluster-medium div {
  background-color: var(--primaryThemeColor);
}

.marker-cluster-large {
  color: var(--textColorOnPrimaryBackground) !important;
}

.marker-cluster-large div {
  background-color: var(--primaryThemeColor);
  font-family: var(--roboto);
}

.leaflet-control-layers {
  display: none;
}

.leaflet-container {
  background-color: var(--white);
}

.blur-map {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: var(--grey300);
}

.blurred-map-marker > div.inline-svg-icon::after {
  content: 'Location not set';
  height: 10px;
  width: 200px;
  margin-left: -81px;
  text-align: center;
  display: inline-block;
  font-size: var(--font-size-14px);
  font-family: var(--roboto);
}

.leaflet-popup {
  font-family: var(--roboto);
}

.leaflet-popup-content {
  width: initial !important;
  margin: 10px;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: var(--white);
  color: var(--black);
}

.leaflet-popup-content-wrapper {
  border-radius: var(--borderRadius);
}

::ng-deep .leaflet-container:after,
::ng-deep .leaflet-gesture-handling-touch-warning:after {
  text-align: center !important;
}

::ng-deep .leaflet-bar {
  box-shadow: none !important;
  border: none !important;
}

::ng-deep .leaflet-control-zoom a {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
}

::ng-deep .leaflet-bar a {
  border-radius: var(--borderRadius);
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: var(--black);
  margin-top: 7px;
}

::ng-deep .leaflet-bar a:hover {
  background-color: var(--cardHoverColor);
}

::ng-deep .leaflet-control-zoom-in {
  margin-top: 10px;
}

::ng-deep .leaflet-control-zoom-out {
  margin-top: 15px;
}

.leaflet-container {
  border: 1px solid var(--borderColor);
  border-radius: var(--borderRadius);
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 1px solid var(--borderColor);
  background-color: var(--backgroundColor);
}

.leaflet-bar a {
  color: var(--black);
  background-color: var(--backgroundColor);
}

.leaflet-touch .leaflet-bar a {
  height: 34px;
}
