@use 'theme/breakpoints' as breakpoints;

:root {
  --fullScreenDialogHeaderHeight: 110px;
  --dialogPadding: 24px;

  // Dialog widths
  --dialogWidthSm: 350px;
  --dialogWidthMd: 550px;
  --dialogWidthLg: 700px;
  --dialogWidthXl: 980px;

  --mat-dialog-container-small-max-width: 100vw;
}

.rb-dialog,
.rb-dialog-sm,
.rb-dialog-md,
.rb-dialog-lg,
.rb-dialog-xl,
.rb-dialog-full {
  height: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  --mat-dialog-container-min-width: 320px;

  // Regular size is md
  width: var(--dialogWidthMd);

  &-sm {
    width: var(--dialogWidthSm);
    max-height: calc(100vh - 40px);
  }
  &-md {
    width: var(--dialogWidthMd);
    max-height: calc(100vh - 40px);
  }
  &-lg {
    width: var(--dialogWidthLg);
    max-height: calc(100vh - 40px);
  }
  &-xl {
    width: var(--dialogWidthXl);
    max-height: calc(100vh - 40px);
  }
  &-full {
    width: 100vw;
    height: 100vh;
    --mat-dialog-container-max-width: 100vw;
    background-color: var(--white);
    padding: 0;

    .rb-content-container.no-footer {
      max-height: none;
    }
  }

  .mat-mdc-dialog-container {
    padding: 0;

    .mdc-dialog__surface {
      padding: 0;
    }
  }

  .rb-content-container {
    margin: 0;
    padding: 16px var(--dialogPadding);
    overflow-y: auto;
    overflow-x: hidden;
  }

  &.rb-extra-py .rb-content-container {
    padding: 24px;
  }

  &.rb-fixed-height .rb-content-container {
    height: 900px;
  }

  .rb-dialog-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    padding: 20px 20px 20px 24px;
    border-bottom: 1px solid var(--borderColor);

    .rb-dialog-title-container {
      display: flex;
      flex-grow: 1;
      overflow: hidden;
      align-items: center;

      .rb-dialog-title-header {
        margin: 0;
        padding: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .rb-dialog-actions {
      display: flex;
    }
  }

  // take the iOS notch into account on mobile fullscreen dialogs
  &-full .rb-dialog-header-container {
    padding: max(14px, env(safe-area-inset-top)) 20px 14px;
  }

  .rb-dialog-footer-container {
    @extend .flex-gap-row-20;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--grey50);
    border-top: 1px solid var(--borderColor);
    padding: 20px;

    .hint-text {
      color: var(--grey500);
      font-size: var(--font-size-12px);
      line-height: var(--line-height-18px);
    }
  }

  .button-confirm {
    &.button-confirm-primary {
      background-color: var(--primaryThemeColor);
      color: var(--textColorOnPrimaryBackground);
    }
    &.button-confirm-warn {
      background-color: var(--statusOrange);
      color: var(--black);
    }

    &.button-confirm-danger {
      background-color: var(--warnColor);
      color: var(--white);
    }

    &.button-confirm-success {
      background-color: var(--statusGreen);
      color: var(--white);
    }
    &.button-confirm-info {
      background-color: var(--statusBlue);
      color: var(--white);
    }
  }

  // Setting full width at certain breakpoint //
  &-md-full-width {
    @media #{breakpoints.$mat-lt-md} {
      --mat-dialog-container-max-width: 100vw;
    }
  }

  // Mobile stuff //
  @mixin fullscreen-base-styling {
    &:not(.confirmation) {
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      --mat-dialog-container-max-width: 100vw;
      --mat-dialog-content-max-height: calc(
        100vh - var(--dialogHeaderHeightSm) - env(safe-area-inset-top)
      );
      background-color: var(--white);
      padding: 0;

      .rb-content-container {
        box-sizing: border-box;
        height: var(--mat-dialog-content-max-height);
        max-height: var(--mat-dialog-content-max-height);
      }

      &:has(.rb-dialog-footer-container) {
        --mat-dialog-content-max-height: calc(
          100vh - var(--dialogHeaderHeightSm) - var(--dialogFooterHeight) - env(safe-area-inset-top)
        );
      }

      .arrow-back {
        display: flex;
        vertical-align: baseline;
        flex-shrink: 0;
      }

      .close-sign,
      .rb-dialog-footer-container {
        display: none;
      }

      .mobile-action-buttons {
        display: flex;
      }

      .rb-dialog-header-container {
        padding: max(var(--dialogTopPadding), env(safe-area-inset-top)) var(--dialogPadding)
          var(--dialogTopPadding);
      }
    }

    &.confirmation {
      .rb-dialog-header-container {
        border: none;

        .rb-dialog-actions {
          display: none;
        }
      }

      .rb-dialog-footer-container {
        background: none;
        border: none;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: stretch;
        padding: 0 var(--dialogPadding) var(--dialogPadding) var(--dialogPadding);
      }
    }
  }

  @media #{breakpoints.$mat-lt-sm} {
    @include fullscreen-base-styling;
  }

  &-md-full-screen {
    @media #{breakpoints.$mat-lt-md} {
      @include fullscreen-base-styling;
    }
  }

  @media #{breakpoints.$mat-gt-xs} {
    .arrow-back:not(.no-hide),
    .mobile-action-buttons {
      display: none;
    }

    .close-sign {
      display: block;
    }

    .rb-dialog-footer-container {
      display: flex;
    }
  }

  .footer-icon {
    font-size: var(--font-size-18px);
    width: var(--font-size-18px);
    min-width: var(--font-size-18px);
    height: var(--font-size-18px);
    margin-right: 8px;
    align-self: center;
  }

  .footer-text {
    margin: 0px;
    font-size: var(--font-size-12px);
    line-height: var(--line-height-18px);
  }
}

// --- //

/**
 * @deprecated, use .rb-dialog
 */
.dialog-header-container {
  display: flex;
  flex-direction: row;
  padding-top: max(var(--dialogTopPadding), env(safe-area-inset-top));
  padding-right: 24px;
  padding-bottom: 20px;
  padding-left: 24px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  background-color: var(--white);
  top: 0;
  height: 77px;
  margin-top: 0;
  margin-left: -24px;
  width: calc(100% + 48px);
  border-bottom: 1px solid var(--borderColor);
  box-sizing: border-box;
  z-index: 1;

  .dialog-title-container {
    display: flex;
    align-items: center;

    .dialog-title-header {
      margin: 0;
      padding: 0;
    }
  }
}

/**
 * @deprecated, use .rb-dialog
 */
.dialog-footer-container {
  display: flex;
  flex-direction: row;
  padding: 24px 20px;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--grey50);
  border-top: 1px solid var(--borderColor);

  position: sticky;
  bottom: -24px;
  border-top: 1px solid var(--borderColor);
  margin: 20px -24px -24px -24px;
}

:root {
  --mobile-header-height: 77px;
  --content-container-margin-top: 20px;
  --mobile-content-max-height: calc(
    var(--height) - (var(--mobile-header-height) + 2 * var(--content-container-margin-top))
  );
}

/**
 * @deprecated, use .rb-dialog
 */
.content-container {
  margin: var(--content-container-margin-top) 0 0 0;
  max-height: var(--mobile-content-max-height);
}

/**
 * @deprecated, use .rb-dialog
 */
.pdf-preview-dialog {
  --mat-dialog-container-max-width: calc(90vw - 76px - 20px);
  width: calc(90vw - 76px - 20px);
  height: calc(90vh - 76px - 20px);

  @media #{breakpoints.$mat-lt-sm} {
    width: 100vw;
    --mat-dialog-container-max-width: 100vw;
    height: 100vh;
  }
}

/**
 * @deprecated, use .rb-dialog
 */
.mat-mdc-dialog-container {
  height: inherit !important;
}

/**
 * @deprecated, use .rb-dialog
 */
.mobile-device-full-screen {
  --mat-dialog-container-max-width: 100vw;
  width: 100vw !important;
  height: 100vh !important;
  // This is a funny way of removing the border radius of the overlay:
  // we don't remove it but make the color behind it the same as the foreground color
  background-color: var(--white);
  // This padding is important: is prevents the content to be visible behind the header
  // on scrolling
  padding: 0 !important;
}

/**
 * @deprecated, use .rb-dialog
 */
.middle-size-dialog {
  --mat-dialog-container-max-width: 550px;
  --mat-dialog-container-min-width: 320px;
  width: 550px;
  height: auto;
  overflow-y: hidden;
  overflow-x: hidden;

  @media #{breakpoints.$mat-lt-md} {
    padding: 0px;
  }

  &-field-repeater {
    --mat-dialog-container-max-width: 700px;
    width: 700px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
    width: 100%;
  }

  .icon-primary {
    font-size: var(--icon-60px);
    height: var(--icon-60px);
    width: var(--icon-60px);
    min-width: var(--icon-60px);
    color: var(--primaryThemeColor);
  }

  .remberg-dialog-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--white);
    padding-bottom: 10px;
    margin-top: -10px;
  }

  .dialog-actions {
    display: flex;
    align-items: center;
  }

  .hint-text {
    margin: 5px 0px;
    width: 100%;
  }
}

.positioned-dialog {
  padding: 0;
}

/**
 * @deprecated, use .rb-dialog
 */
.middle-size-dialog-field-repeater {
  width: 700px;
  --mat-dialog-container-min-width: 320px;
  height: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 20px;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
    width: 100%;
  }

  .icon-primary {
    font-size: var(--icon-60px);
    height: var(--icon-60px);
    width: var(--icon-60px);
    min-width: var(--icon-60px);
    color: var(--primaryThemeColor);
  }

  .remberg-dialog-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--white);
    padding-bottom: 10px;
    margin-top: -10px;
  }

  .dialog-actions {
    display: flex;
    align-items: center;
  }

  .hint-text {
    margin: 5px 0;
    width: 100%;
  }
}

/**
 * @deprecated, use .rb-dialog
 */
.filter-desktop-dialog {
  --mat-dialog-container-min-width: min(680px, 85vw);
  width: fit-content;
  --mat-dialog-container-max-width: 95vw;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    overflow: hidden;
  }
}

.scheduling-board-mat-dialog {
  .dialog-header {
    padding-top: max(var(--dialogTopPadding), env(safe-area-inset-top));
  }
}

/**
 * @deprecated, use .rb-dialog
 */
.file-drive-creation-dialog,
.confirmation-dialog.file-drive-creation-dialog {
  width: 450px;
}

/**
 * @deprecated, use .rb-dialog
 */
.file-drive-preview-dialog {
  --mat-dialog-container-max-width: 1000px;
  width: 900px;
  @media #{breakpoints.$mat-xs} {
    width: 100px;
  }
}

/**
 * Confirmation Dialogs
 */
.confirmation-dialog {
  --mat-dialog-container-max-width: 350px;
  --mat-dialog-container-min-width: 320px;
  width: 350px;
  height: auto;
  overflow-y: hidden;
  overflow-x: hidden;

  @media #{breakpoints.$mat-lt-sm} {
    --mat-dialog-container-max-width: 280px;
    --mat-dialog-container-min-width: 280px;
    width: 280px;
  }

  .header-wrapper {
    padding-top: var(--dialogTopPadding);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon-primary {
    font-size: var(--icon-60px);
    height: var(--icon-60px);
    width: var(--icon-60px);
    min-width: var(--icon-60px);
    color: var(--primaryThemeColor);
  }

  .header-text {
    text-align: center;
  }

  .content-text {
    text-align: center;
    white-space: break-spaces;
    font-size: var(--font-size-14px);
    margin-bottom: 30px;
  }

  .html-content {
    text-align: center;
    white-space: inherit;
    overflow-wrap: break-word;
    font-size: var(--font-size-14px);
    margin-bottom: 30px;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .checkbox-item-list {
    margin: -15px 0 20px;
    align-items: center;
    display: flex;
    flex-direction: column;

    .checkbox-item {
      font-size: var(--font-size-14px);
      margin: 5px 0;
    }
  }

  .buttons-container {
    flex-flow: column wrap;
    box-sizing: border-box;
    display: flex;
  }

  .button-confirm {
    margin-top: 10px;
  }

  .footer-container {
    display: flex;
    padding-top: 16px;
  }

  .footer-icon {
    font-size: var(--font-size-18px);
    width: var(--font-size-18px);
    min-width: var(--font-size-18px);
    height: var(--font-size-18px);
    margin-right: 8px;
  }

  .footer-text {
    margin: 0px;
    font-size: var(--font-size-12px);
    line-height: var(--line-height-18px);
  }
}

.delete-dialog {
  mat-icon.icon-primary {
    color: var(--warnColor);
  }
  .mat-mdc-raised-button.mat-primary:not(:disabled).button-confirm {
    background-color: var(--warnColor);
    color: var(--white);
  }

  mat-icon.footer-icon {
    color: var(--warnColor);
  }
}

.unassign-asset-failure-dialog {
  mat-icon.icon-primary {
    color: var(--warnColor);
  }
  .mat-mdc-raised-button.mat-primary:not(:disabled).button-confirm {
    background-color: var(--black);
    color: var(--white);
  }
}

.lock-items-dialog {
  .content-text {
    white-space: normal;
  }
  box-shadow: 0px 4px 8px rgba(97, 97, 97, 0.12);
}

.info-dialog {
  mat-icon.icon-primary {
    color: var(--statusBlue);
  }
  .mat-mdc-raised-button.mat-primary:not(:disabled).button-confirm {
    background-color: var(--statusBlue);
    color: var(--white);
  }

  mat-icon.footer-icon {
    color: var(--statusBlue);
  }
}

.warning-dialog {
  mat-icon.icon-primary {
    color: var(--statusOrange);
  }
  .mat-mdc-raised-button.mat-primary:not(:disabled).button-confirm {
    background-color: var(--statusOrange);
    color: var(--black);
  }
  mat-icon.footer-icon {
    color: var(--statusOrange);
  }
}

.success-dialog {
  mat-icon.icon-primary {
    color: var(--statusGreen);
  }
  .mat-mdc-raised-button.mat-primary:not(:disabled).button-confirm {
    background-color: var(--statusGreen);
    color: var(--white);
  }
  mat-icon.footer-icon {
    color: var(--statusGreen);
  }
}

.remove-dialog {
  mat-icon.icon-primary {
    color: var(--warnColor);
  }
  .mat-mdc-raised-button.mat-primary:not(:disabled).button-confirm {
    background-color: var(--white);
    border: 1px solid var(--warnColor) !important;
    color: var(--warnColor);
  }
  mat-icon.footer-icon {
    color: var(--warnColor);
  }
}

.warn-info-dialog {
  mat-icon.icon-primary {
    color: var(--warnColor);
  }
  .mat-mdc-raised-button.mat-primary:not(:disabled).button-confirm {
    background-color: var(--white);
  }
}

.floating-editor-backdrop {
  background-color: rgba(48, 52, 64, 0.2);
}

.mat-mdc-menu-panel.view-switch-menu {
  min-width: 250px;
}

.rb-dialog-multi-language-edit {
  --mat-dialog-container-min-width: 500px;
}

#file-upload-progress .mat-mdc-dialog-surface {
  padding: 0 !important;
}
