/* stylelint-disable*/
/*
* Check https://materialtheme.arcsine.dev/
*/

/* Material CSS */
@use '@angular/material' as mat;

/* Fonts */
@import '../../assets/fonts/material-icons/material-icons.scss';
@import '../../assets/fonts/roboto/scss/roboto-fontface.scss';

// Primary variables
$theme-primary-palette: (
  50: var(--primaryThemeColorLight),
  100: var(--primaryThemeColorLight),
  200: var(--primaryThemeColorLight),
  300: var(--primaryThemeColorLight),
  400: var(--primaryThemeColor),
  500: var(--primaryThemeColor),
  600: var(--primaryThemeColor),
  700: var(--primaryThemeColorDark),
  800: var(--primaryThemeColorDark),
  900: var(--primaryThemeColorDark),
  contrast: (
    50: var(--primaryThemeColorLight),
    100: var(--primaryThemeColorLight),
    200: var(--primaryThemeColorLight),
    300: var(--primaryThemeColorLight),
    400: var(--textColorOnPrimaryBackground),
    500: var(--textColorOnPrimaryBackground),
    600: var(--textColorOnPrimaryBackground),
    700: var(--primaryThemeColorDark),
    800: var(--primaryThemeColorDark),
    900: var(--primaryThemeColorDark),
  ),
);
$theme-primary: mat.define-palette($theme-primary-palette, 500, 300, 700);

// Accent variables
$theme-accent-palette: (
  50: var(--blackLight),
  100: var(--blackLight),
  200: var(--blackLight),
  300: var(--blackLight),
  400: var(--black),
  500: var(--black),
  600: var(--black),
  700: var(--blackDark),
  800: var(--blackDark),
  900: var(--blackDark),
  contrast: (
    50: var(--blackLight),
    100: var(--blackLight),
    200: var(--blackLight),
    300: var(--blackLight),
    400: var(--white),
    500: var(--white),
    600: var(--white),
    700: var(--blackDark),
    800: var(--blackDark),
    900: var(--blackDark),
  ),
);
$theme-accent: mat.define-palette($theme-accent-palette, 500, 300, 700);

// Warn variables
$theme-warn-palette: (
  50: var(--warnColorLight),
  100: var(--warnColorLight),
  200: var(--warnColorLight),
  300: var(--warnColorLight),
  400: var(--warnColor),
  500: var(--warnColor),
  600: var(--warnColor),
  700: var(--warnColorDark),
  800: var(--warnColorDark),
  000: var(--warnColorDark),
  contrast: (
    50: var(--warnColorLight),
    100: var(--warnColorLight),
    200: var(--warnColorLight),
    300: var(--warnColorLight),
    400: var(--white),
    500: var(--white),
    600: var(--white),
    700: var(--warnColorDark),
    800: var(--warnColorDark),
    900: var(--warnColorDark),
  ),
);
$theme-warn: mat.define-palette($theme-warn-palette, 500, 300, 700);

// Default settings taken from define-legacy-typography-config in _typography.scss
// Default Font Input that is partly overwritten in styles/fonts.scss
// Subheading-2 and Subheading-1 are overwritten in theme.scss. Subheading-2 here is reused for e.g. mat-list and not only as h2
$theme-typography: mat.define-typography-config(
  $font-family: var(--roboto),
  $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2:
    mat.define-typography-level(56px, 56px, var(--font-weight-regular), $letter-spacing: -0.02em),
  $headline-3:
    mat.define-typography-level(45px, 48px, var(--font-weight-regular), $letter-spacing: -0.005em),
  $headline-4: mat.define-typography-level(34px, 40px, var(--font-weight-regular)),
  $headline-5:
    mat.define-typography-level(
      var(--font-size-24px),
      var(--line-height-32px),
      var(--font-weight-regular)
    ),
  $headline-6:
    mat.define-typography-level(
      var(--font-size-20px),
      var(--line-height-30px),
      var(--font-weight-bold)
    ),
  $subtitle-1:
    mat.define-typography-level(
      var(--font-size-16px),
      var(--line-height-28px),
      var(--font-weight-bold)
    ),
  $subtitle-2:
    mat.define-typography-level(
      var(--font-size-15px),
      var(--line-height-24px),
      var(--font-weight-regular)
    ),
  $body-2:
    mat.define-typography-level(
      var(--font-size-14px),
      var(--line-height-20px),
      var(--font-weight-bold)
    ),
  $body-1:
    mat.define-typography-level(
      var(--font-size-14px),
      var(--line-height-20px),
      var(--font-weight-regular)
    ),
  $caption:
    mat.define-typography-level(
      var(--font-size-12px),
      var(--line-height-18px),
      var(--font-weight-regular)
    ),
  $button:
    mat.define-typography-level(
      var(--font-size-14px),
      var(--line-height-14px),
      var(--font-weight-bold)
    ),
  $overline:
    mat.define-typography-level(inherit, var(--line-height-1Dot125), var(--font-weight-regular)),
);

// Theme Setup
$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $theme-typography,
    density: 0,
  )
);

@include mat.core();
@include mat.core-theme($theme);
@include mat.all-component-themes($theme);

/* Custom Variable overwrites on top of theming */
html {
  // --mat-toolbar-title-text-font: var(--roboto);
  // --mat-toolbar-title-text-line-height: var(--line-height-30px);
  // --mat-toolbar-title-text-size: var(--font-size-20px);
  // --mat-toolbar-title-text-tracking: normal;
  // --mat-toolbar-title-text-weight: var(--font-weight-bold);
}
html {
  // --mat-toolbar-standard-height: 64px;
  // --mat-toolbar-mobile-height: 56px;
}
html {
  --mat-toolbar-container-background-color: var(--backgroundColor);
  --mat-toolbar-container-text-color: var(--white);
}
html {
  // --mat-stepper-container-text-font: var(--roboto);
  // --mat-stepper-header-label-text-font: var(--roboto);
  // --mat-stepper-header-label-text-size: var(--font-size-14px);
  // --mat-stepper-header-label-text-weight: var(--font-weight-bold);
  // --mat-stepper-header-error-state-label-text-size: var(--font-size-14px);
  // --mat-stepper-header-selected-state-label-text-size: var(--font-size-14px);
  // --mat-stepper-header-selected-state-label-text-weight: var(--font-weight-regular);
}
html {
  // --mat-stepper-header-height: 72px;
}
html {
  // --mat-stepper-header-icon-foreground-color: var(--textColorOnPrimaryBackground);
  // --mat-stepper-header-selected-state-icon-background-color: var(--primaryThemeColor);
  // --mat-stepper-header-selected-state-icon-foreground-color: var(--textColorOnPrimaryBackground);
  // --mat-stepper-header-done-state-icon-background-color: var(--primaryThemeColor);
  // --mat-stepper-header-done-state-icon-foreground-color: var(--textColorOnPrimaryBackground);
  // --mat-stepper-header-edit-state-icon-background-color: var(--primaryThemeColor);
  // --mat-stepper-header-edit-state-icon-foreground-color: var(--textColorOnPrimaryBackground);
  --mat-stepper-container-color: inherit;
  // --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  // --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  // --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  // --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  // --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  // --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  // --mat-stepper-header-error-state-label-text-color: var(--warnColor);
  // --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  // --mat-stepper-header-error-state-icon-foreground-color: var(--warnColor);
  // --mat-stepper-header-error-state-icon-background-color: transparent;
}
html {
  // --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: var(--white);
  // --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  // --mat-sidenav-content-background-color: #fafafa;
  // --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  // --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}
html {
  // --mat-icon-color: inherit;
}
html {
  // --mat-grid-list-tile-header-primary-text-size: var(--font-size-14px);
  // --mat-grid-list-tile-header-secondary-text-size: var(--font-size-12px);
  // --mat-grid-list-tile-footer-primary-text-size: var(--font-size-14px);
  // --mat-grid-list-tile-footer-secondary-text-size: var(--font-size-12px);
}
html {
  // --mat-expansion-header-text-font: var(--roboto);
  // --mat-expansion-header-text-size: var(--font-size-15px);
  // --mat-expansion-header-text-weight: var(--font-weight-regular);
  // --mat-expansion-header-text-line-height: inherit;
  // --mat-expansion-header-text-tracking: inherit;
  // --mat-expansion-container-text-font: var(--roboto);
  // --mat-expansion-container-text-line-height: var(--line-height-20px);
  // --mat-expansion-container-text-size: var(--font-size-14px);
  // --mat-expansion-container-text-tracking: normal;
  // --mat-expansion-container-text-weight: var(--font-weight-bold);
}
html {
  // --mat-expansion-header-collapsed-state-height: 48px;
  // --mat-expansion-header-expanded-state-height: 64px;
}
html {
  --mat-expansion-container-background-color: var(--white);
  --mat-expansion-container-text-color: var(--black);
  // --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  // --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  // --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  // --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: var(--black);
  --mat-expansion-header-description-color: var(--primaryThemeColor);
  // --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}
html {
  // --mat-divider-color: rgba(0, 0, 0, 0.12);
}
html {
  // --mat-datepicker-calendar-text-font: var(--roboto);
  // --mat-datepicker-calendar-text-size: 13px;
  // --mat-datepicker-calendar-body-label-text-size: var(--font-size-14px);
  // --mat-datepicker-calendar-body-label-text-weight: var(--font-weight-bold);
  // --mat-datepicker-calendar-period-button-text-size: var(--font-size-14px);
  // --mat-datepicker-calendar-period-button-text-weight: var(--font-weight-bold);
  // --mat-datepicker-calendar-header-text-size: 11px;
  // --mat-datepicker-calendar-header-text-weight: var(--font-weight-bold);
}
html {
  // --mat-datepicker-calendar-date-selected-state-text-color: var(--textColorOnPrimaryBackground);
  // --mat-datepicker-calendar-date-selected-state-background-color: var(--primaryThemeColor);
  // --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 0, 0, 0.26);
  // --mat-datepicker-calendar-date-today-selected-state-outline-color: var(
  // --textColorOnPrimaryBackground
  // );
  // --mat-datepicker-calendar-date-focus-state-background-color: var(--primaryThemeColor);
  // --mat-datepicker-calendar-date-hover-state-background-color: var(--primaryThemeColor);
  // --mat-datepicker-toggle-active-state-icon-color: var(--primaryThemeColor);
  // --mat-datepicker-calendar-date-in-range-state-background-color: rgba(
  // var(--primaryThemeColor),
  // 0.2
  // );
  // --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  // --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  // --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  // --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  // --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  // --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  // --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  // --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  // --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  // --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  // --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  // --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  // --mat-datepicker-calendar-date-outline-color: transparent;
  // --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  // --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  // --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  // --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  // --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: var(--white);
  // --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}
html {
  // --mat-legacy-button-toggle-text-font: var(--roboto);
  // --mat-standard-button-toggle-text-font: var(--roboto);
}
html {
  // --mat-standard-button-toggle-height: 48px;
}
html {
  // --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  // --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  // --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  // --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  // --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  // --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  // --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  // --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: var(--white);
  --mat-standard-button-toggle-state-layer-color: var(--black);
  // --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  // --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  // --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: var(--white);
  // --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  // --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  // --mat-standard-button-toggle-divider-color: #e0e0e0;
}
html {
  // --mat-bottom-sheet-container-text-font: var(--roboto);
  // --mat-bottom-sheet-container-text-line-height: var(--line-height-20px);
  // --mat-bottom-sheet-container-text-size: var(--font-size-14px);
  // --mat-bottom-sheet-container-text-tracking: normal;
  // --mat-bottom-sheet-container-text-weight: var(--font-weight-bold);
}
html {
  // --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: var(--white);
}
html {
  // --mat-badge-text-font: var(--roboto);
  // --mat-badge-text-size: 12px;
  // --mat-badge-text-weight: 600;
  // --mat-badge-small-size-text-size: 9px;
  // --mat-badge-large-size-text-size: 24px;
}
html {
  // --mat-badge-background-color: var(--primaryThemeColor);
  // --mat-badge-text-color: var(--textColorOnPrimaryBackground);
  // --mat-badge-disabled-state-background-color: #b9b9b9;
  // --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

html {
  --mat-table-header-headline-font: var(--roboto);
  // --mat-table-header-headline-line-height: var(--line-height-24px);
  --mat-table-header-headline-size: var(--font-size-12px);
  --mat-table-header-headline-weight: var(--font-weight-bold);
  // --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: var(--roboto);
  // --mat-table-row-item-label-text-line-height: var(--line-height-20px);
  // --mat-table-row-item-label-text-size: var(--font-size-14px);
  --mat-table-row-item-label-text-weight: var(--font-weight-regular);
  // --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: var(--roboto);
  // --mat-table-footer-supporting-text-line-height: var(--line-height-20px);
  // --mat-table-footer-supporting-text-size: var(--font-size-14px);
  // --mat-table-footer-supporting-text-weight: var(--font-weight-bold);
  // --mat-table-footer-supporting-text-tracking: normal;
}
html {
  // --mat-table-header-container-height: 56px;
  // --mat-table-footer-container-height: 52px;
  // --mat-table-row-item-container-height: 52px;
}
html {
  --mat-table-background-color: var(--white);
  --mat-table-header-headline-color: var(--grey800);
  --mat-table-row-item-label-text-color: var(--black);
  // --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}
html {
  // --mdc-checkbox-state-layer-size: 40px;
}
html {
  // --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  // --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  // --mdc-checkbox-selected-checkmark-color: var(--black);
  // --mdc-checkbox-selected-focus-icon-color: var(--black);
  // --mdc-checkbox-selected-hover-icon-color: var(--black);
  // --mdc-checkbox-selected-icon-color: var(--black);
  // --mdc-checkbox-selected-pressed-icon-color: var(--black);
  // --mdc-checkbox-unselected-focus-icon-color: #212121;
  // --mdc-checkbox-unselected-hover-icon-color: #212121;
  // --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  // --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  // --mdc-checkbox-selected-focus-state-layer-color: var(--black);
  // --mdc-checkbox-selected-hover-state-layer-color: var(--black);
  // --mdc-checkbox-selected-pressed-state-layer-color: var(--black);
  // --mdc-checkbox-unselected-focus-state-layer-color: var(--black);
  // --mdc-checkbox-unselected-hover-state-layer-color: var(--black);
  // --mdc-checkbox-unselected-pressed-state-layer-color: var(--black);
  // --mdc-checkbox-selected-checkmark-color: var(--textColorOnPrimaryBackground);
}
html {
  // --mat-paginator-container-text-font: var(--roboto);
  // --mat-paginator-container-text-line-height: var(--line-height-18px);
  // --mat-paginator-container-text-size: var(--font-size-12px);
  // --mat-paginator-container-text-tracking: normal;
  // --mat-paginator-container-text-weight: var(--font-weight-regular);
  // --mat-paginator-select-trigger-text-size: var(--font-size-12px);
}
html {
  // --mat-paginator-container-size: 56px;
}
html {
  --mat-paginator-container-text-color: var(--black);
  --mat-paginator-container-background-color: var(--white);
  // --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  // --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}
html {
  // --mat-menu-item-label-text-font: var(--roboto);
  // --mat-menu-item-label-text-size: var(--font-size-14px);
  // --mat-menu-item-label-text-tracking: normal;
  // --mat-menu-item-label-text-line-height: var(--line-height-20px);
  // --mat-menu-item-label-text-weight: var(--font-weight-regular);
}
html {
  --mat-menu-item-label-text-color: var(--black);
  --mat-menu-item-icon-color: var(--black);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: var(--white);
}
html {
  --mat-autocomplete-background-color: var(--white);
}
html {
  // --mat-select-trigger-text-font: var(--roboto);
  // --mat-select-trigger-text-line-height: var(--line-height-20px);
  // --mat-select-trigger-text-size: var(--font-size-14px);
  // --mat-select-trigger-text-tracking: normal;
  // --mat-select-trigger-text-weight: var(--font-weight-regular);
}
html {
  --mat-select-panel-background-color: var(--white);
  --mat-select-enabled-trigger-text-color: var(--black);
  // --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  // --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  // --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  // --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  // --mat-select-focused-arrow-color: var(--primaryThemeColor);
  // --mat-select-invalid-arrow-color: var(--warnColor);
}
html {
  // --mdc-filled-text-field-label-text-font: var(--roboto);
  // --mdc-filled-text-field-label-text-size: var(--font-size-14px);
  // --mdc-filled-text-field-label-text-tracking: normal;
  // --mdc-filled-text-field-label-text-weight: var(--font-weight-regular);
  // --mdc-outlined-text-field-label-text-font: var(--roboto);
  // --mdc-outlined-text-field-label-text-size: var(--font-size-14px);
  // --mdc-outlined-text-field-label-text-tracking: normal;
  // --mdc-outlined-text-field-label-text-weight: var(--font-weight-regular);
  // --mat-form-field-container-text-font: var(--roboto);
  --mat-form-field-container-text-line-height: var(--line-height-24px);
  // --mat-form-field-container-text-size: var(--font-size-14px);
  // --mat-form-field-container-text-tracking: normal;
  // --mat-form-field-container-text-weight: var(--font-weight-regular);
  // --mat-form-field-outlined-label-text-populated-size: var(--font-size-14px);
  // --mat-form-field-subscript-text-font: var(--roboto);
  --mat-form-field-subscript-text-line-height: var(--line-height-1rem);
  --mat-form-field-subscript-text-size: var(--font-size-Dot75rem);
  // --mat-form-field-subscript-text-tracking: normal;
  // --mat-form-field-subscript-text-weight: var(--font-weight-regular);
}
html {
  // --mdc-filled-text-field-caret-color: var(--primaryThemeColor);
  // --mdc-filled-text-field-focus-active-indicator-color: var(--primaryThemeColor);
  // --mdc-filled-text-field-focus-label-text-color: var(--primaryThemeColor);
  --mdc-filled-text-field-container-color: transparent;
  // --mdc-filled-text-field-disabled-container-color: #fafafa;
  // --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  // --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: var(--black);
  // --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  // --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  // --mdc-filled-text-field-error-focus-label-text-color: var(--warnColor);
  // --mdc-filled-text-field-error-label-text-color: var(--warnColor);
  // --mdc-filled-text-field-error-caret-color: var(--warnColor);
  --mdc-filled-text-field-active-indicator-color: var(--borderColor);
  // --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: var(--black);
  // --mdc-filled-text-field-error-active-indicator-color: var(--warnColor);
  // --mdc-filled-text-field-error-focus-active-indicator-color: var(--warnColor);
  // --mdc-filled-text-field-error-hover-active-indicator-color: var(--warnColor);
  // --mdc-outlined-text-field-caret-color: var(--primaryThemeColor);
  // --mdc-outlined-text-field-focus-outline-color: var(--primaryThemeColor);
  // --mdc-outlined-text-field-focus-label-text-color: var(--primaryThemeColor);
  // --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  // --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: var(--black);
  // --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  // --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  // --mdc-outlined-text-field-error-caret-color: var(--warnColor);
  // --mdc-outlined-text-field-error-focus-label-text-color: var(--warnColor);
  // --mdc-outlined-text-field-error-label-text-color: var(--warnColor);
  --mdc-outlined-text-field-outline-color: var(--borderColor);
  // --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: var(--primaryThemeColor);
  // --mdc-outlined-text-field-error-focus-outline-color: var(--warnColor);
  // --mdc-outlined-text-field-error-hover-outline-color: var(--warnColor);
  // --mdc-outlined-text-field-error-outline-color: var(--warnColor);
  // --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}
html {
  // --mat-optgroup-label-text-font: var(--roboto);
  // --mat-optgroup-label-text-line-height: var(--line-height-20px);
  // --mat-optgroup-label-text-size: var(--font-size-14px);
  // --mat-optgroup-label-text-tracking: normal;
  // --mat-optgroup-label-text-weight: var(--font-weight-regular);
}
html {
  // --mat-option-label-text-font: var(--roboto);
  // --mat-option-label-text-line-height: var(--line-height-20px);
  // --mat-option-label-text-size: var(--font-size-14px);
  // --mat-option-label-text-tracking: normal;
  // --mat-option-label-text-weight: var(--font-weight-regular);
}
html {
  --mat-optgroup-label-text-color: var(--black);
}
html {
  // --mat-option-selected-state-label-text-color: var(--primaryThemeColor);
  --mat-option-label-text-color: var(--black);
  --mat-option-hover-state-layer-color: var(--grey50);
  --mat-option-focus-state-layer-color: var(--grey50);
  --mat-option-selected-state-layer-color: var(--grey50);
}
html,
.mat-mdc-row,
.mdc-data-table__content,
.mat-md-button,
.mdc-button,
.mat-mdc-slide-toggle .mdc-form-field,
.mat-mdc-checkbox .mdc-form-field,
.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before,
.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control,
.mat-mdc-form-field,
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text,
.mat-mdc-option {
  -webkit-font-smoothing: auto !important;
}
/* stylelint-enable*/
