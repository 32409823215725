/* Import and override for global and device-specific Ionic CSS */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Specific OS imports*/
@import './ionic/android.scss';
@import './ionic/ios.scss';
@import './ionic/electron.scss';

.mobile-time-picker {
  z-index: 40000 !important;

  .picker-toolbar {
    .sc-ion-picker-ios,
    .sc-ion-picker-md {
      color: var(--primaryThemeColor);
    }
  }

  .picker-col {
    .picker-opts {
      .picker-opt.picker-opt-selected {
        color: var(--primaryThemeColor);
      }
    }
  }
}

.isIonic {
  .not-clickable-inside-ionic-app,
  .not-clickable-inside-ionic-app * {
    pointer-events: none !important;
    text-decoration: none !important;
  }

  .not-visible-inside-ionic-app {
    display: none !important;
  }
}
